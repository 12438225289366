/* Font family */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #252c32;
  overflow-x: hidden;
}
.right-padding{
  padding-right: 15px;
}
.main-solidarity-wrapper {
  overflow-x: hidden;
}
h1 {
  font-weight: 900;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.022em;
  color: #252c32;
}
h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.019em;
  color: #252c32;
}
h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.014em;
  color: #252c32;
}
h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.006em;
  text-transform: capitalize;
  color: #252c32;
}
h5 {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.018em;
  text-transform: uppercase;
  color: #252c32;
}
p {
  margin: 0 0 15px;
}
/* .ng-dropdown-panel{margin-top: 70px !important;} */
.ng-dropdown-panel.ng-select-top {
  margin: 4px 0;
}
/* .answer-tag{color: rgb(66 66 66 / 50%);padding: 6px;} */
.answer-tag-field {
  border: none !important;
  background: transparent !important;
  color: black;
  padding-left: 0 !important;
}
.answer-tag-dropdown .ng-arrow-wrapper {
  background: none !important;
}
.answer-tag-dropdown,
.answer-tag-dropdown .ng-select-container {
  color: rgb(66 66 66 / 50%) !important;
  /* padding: 6px; */
  border: none !important;
  background: none !important;
}
.ng-select-container.answer-tag-dropdown,
.answer-tag-dropdown .ng-select-container {
  box-shadow: none !important;
}

.padding-lr{
  padding-left: 10px;
  padding-right: 10px;
}

.error-message {
  font-size: 12px;
  line-height: 16px;
  color: #f2271c;
}
.heading-text-blue {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #00719a;
  margin: 0;
}
.custom-heading-wrapper {
  margin: 0 0 20px;
}
.custom-heading-wrapper i {
  color: #00719a;
  font-size: 16px;
  padding-right: 14px;
}
.page-sub-heading {
  color: #00719a;
}
.page-sub-heading .bi {
  font-size: 18px;
  margin: 0 13px 0 0;
}
.page-sub-heading .heading-text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
}
.btn.btn-light-blue {
  margin: 0 10px 10px 0;
  padding: 4px 12px;
  min-width: 130px;
  height: 40px;
  background: #00a9e7;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #f6f8f9;
  text-transform: capitalize;
}
/* .btn.btn-light-blue:hover,
.btn.btn-light-blue:active{background: #000;color: #F6F8F9;} */
.btn.btn-light-blue:hover,
.btn.btn-light-secondary:hover {
  background: #008dc0;
  color: #fff;
}
.btn.btn-light-blue:focus,
.btn.btn-light-secondary:focus {
  background-color: #00a9e7;
  color: #fff;
}
.btn.btn-light-blue:active,
.btn.btn-light-secondary:active {
  background: #00719a;
  color: #fff;
}
.btn.btn-light-secondary {
  margin: 0 10px 10px 0;
  padding: 4px 12px;
  min-width: 170px;
  height: 40px;
  background: #fff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #252c32;
}
/* .btn.btn-light-secondary:hover,
.btn.btn-light-secondary:active{background: #000;color: #F6F8F9;} */
.btn-light-transparent {
  background: #ffffff;
  border: 1px solid #00a9e7;
  border-radius: 6px;
  color: #00a9e7;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 7px 16px;
}
.btn-light-transparent:hover {
  background: #00a9e7;
  color: #fff;
}
button:focus:not(:focus-visible),
.btn:focus-visible,
.btn:active:focus {
  outline: none;
  box-shadow: none;
}
a {
  text-decoration: none;
}
a,
button,
.btn,
.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.page-header {
  margin: 0 0 35px;
}
.text-light-blue {
  color: #00a9e7;
}
.text-red {
  color: #f2271c;
}
::placeholder {
  color: #9aa6ac;
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
}
:-ms-input-placeholder {
  color: #9aa6ac;
}
::-ms-input-placeholder {
  color: #9aa6ac;
}
.panel-heading {
  cursor: pointer;
}
.error-404 h1 {
  font-size: 110px;
  line-height: 120px;
}
.error-404 .error-img-holder {
  width: 500px;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 11px;
  height: 8px;
  border: none !important;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  border: none;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #dedede !important;
}
/* input fields design */
.form-control.custom-select-picker.mbl-no-select {
  max-width: 96px;
}
.form-control.custom-select-picker {
  appearance: compact;
  color: #6e7c87;
}
.ng-dropdown-panel {
  display: block !important;
}
.custom-page-form-fields .form-fields form {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.custom-page-form-fields .form-fields {
  max-width: 100%;
}
.form-fields label {
  font-size: 14px;
  line-height: 24px;
}
.custom-page-form-fields .form-fields .form-group {
  margin-right: 90px;
  max-width: 370px;
  width: 100%;
}
/* Upload file design */
.form-fields .file-upload-info .file-info p {
  color: #84919a;
  font-size: 12px;
}
.form-fields .file-upload-info .cross-icon i {
  color: #f2271c;
  cursor: pointer;
}
.form-fields .upload-files-container .drag-file-area {
  border: 1px dashed #d5dadd;
  border-radius: 6px;
  padding: 32px 50px;
  text-align: center;
}
.form-fields .upload-files-container .drag-file-area i {
  color: #b0babf;
  margin-bottom: 8px;
}
.form-fields .upload-files-container .drag-file-area h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #252c32;
  margin-bottom: 8px;
}
.form-fields .upload-files-container .drag-file-area .browse-files {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-fields .upload-files-container .drag-file-area .browse-files-text {
  padding: 8px 16px;
  width: 79px;
  height: 40px;
  background: #fff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #252c32;
  text-transform: capitalize;
  cursor: pointer;
}
.form-fields .upload-files-container .default-file-input {
  opacity: 0;
  display: none;
}
.form-fields .upload-files-container .upload-file-detail {
  background: #f6f8f9;
  border-radius: 6px;
  margin-top: 8px;
  padding: 8px 16px;
}
.form-fields .upload-files-container .verify-file-detail {
  background: #ffefeb;
}
.form-fields
  .upload-files-container
  .upload-file-detail.verify-file-detail
  span {
  color: #f2271c;
}
.form-fields .upload-files-container .upload-file-detail span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9aa6ac;
}
.form-fields
  .upload-files-container
  .upload-file-detail
  span.successful-upload-file,
.form-fields
  .upload-files-container
  .upload-file-detail.successful-upload-file
  span,
.form-fields
  .upload-files-container
  .upload-file-detail.successful-upload-file
  i {
  color: #252c32;
}
.form-fields .upload-files-container .upload-file-detail i {
  color: #9aa6ac;
}
/* .custom-page-form-fields .form-fields .custom-page-fields-wrapper{display: flex;flex-direction: row;flex-wrap: wrap;} */
.password-field {
  min-width: 360px;
}
.custom-page-form-fields
  .form-fields
  .custom-page-fields-wrapper
  .full-w-field {
  max-width: 100%;
}
.custom-page-form-fields.form-fields
  .custom-page-fields-wrapper
  .full-w-field
  .form-control {
  max-width: 370px;
}
.custom-page-form-fields .form-fields small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9aa6ac;
}
.custom-page-form-fields .form-fields .form-group {
  margin-bottom: 40px;
}
.form-fields .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}
.form-fields {
  width: 100%;
  max-width: 360px;
}
.form-fields .form-control {
  height: 40px;
  max-width: 100%;
  background: #fff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
}
.form-fields .form-control:focus {
  border-color: #5b6871;
  box-shadow: none;
}
.form-fields .form-control:disabled {
  color: #1a2024;
  opacity: 0.4;
  background: #f6f8f9;
}
.form-fields textarea.form-control {
  height: 80px;
}
.form-fields .form-control::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9aa6ac;
}
.form-check-input:disabled {
  background: gray;
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
/* logged-in-user */
.logged-in-wrapper {
  height: 100vh;
}
.logged-in-wrapper .bg-img .grad-img-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-animation: animateCloud 6s linear infinite;
  -moz-animation: animateCloud 6s linear infinite;
  animation: animateCloud 6s linear infinite;
}
.logged-in-wrapper .bg-img img {
  width: 100%;
  height: 100%;
}
.logged-in-wrapper .bg-img .grad-img-holder.grad-img-02 {
  animation-delay: 1s;
}
.logged-in-wrapper .bg-img .grad-img-holder.grad-img-03 {
  animation-delay: 2s;
}
.logged-in-wrapper .bg-img .grad-img-holder.grad-img-04 {
  animation-delay: 3s;
}
.logged-in-wrapper .bg-img .grad-img-holder.grad-img-05 {
  animation-delay: 4s;
}
.logged-in-wrapper .bg-img .grad-img-holder.grad-img-06 {
  animation-delay: 5s;
}
@-webkit-keyframes animateCloud {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes animateCloud {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animateCloud {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* .logged-in-wrapper .bg-img{background-image: url(../src/assets/img/gradient-img-01.png);background-size: cover;background-position: center center;height: 100%;-webkit-animation: animateCloud 5s linear infinite;-moz-animation: animateCloud 5s linear infinite;animation: animateCloud 5s linear infinite;}
@-webkit-keyframes animateCloud {
    0% {background-image: url(../src/assets/img/gradient-img-01.png)}
    17% {background-image: url(../src/assets/img/gradient-img-02.png)}
	34% {background-image: url(../src/assets/img/gradient-img-03.png)}
	51% {background-image: url(../src/assets/img/gradient-img-04.png)}
	68% {background-image: url(../src/assets/img/gradient-img-05.png)}
	85% {background-image: url(../src/assets/img/gradient-img-06.png)}
	100% {background-image: url(../src/assets/img/gradient-img-01.png)}
}
@-moz-keyframes animateCloud {
    0% {background-image: url(../src/assets/img/gradient-img-01.png)}
    17% {background-image: url(../src/assets/img/gradient-img-02.png)}
	34% {background-image: url(../src/assets/img/gradient-img-03.png)}
	51% {background-image: url(../src/assets/img/gradient-img-04.png)}
	68% {background-image: url(../src/assets/img/gradient-img-05.png)}
	85% {background-image: url(../src/assets/img/gradient-img-06.png)}
	100% {background-image: url(../src/assets/img/gradient-img-01.png)}
}
@keyframes animateCloud {
    0% {background-image: url(../src/assets/img/gradient-img-01.png)}
    17% {background-image: url(../src/assets/img/gradient-img-02.png)}
	34% {background-image: url(../src/assets/img/gradient-img-03.png)}
	51% {background-image: url(../src/assets/img/gradient-img-04.png)}
	68% {background-image: url(../src/assets/img/gradient-img-05.png)}
	85% {background-image: url(../src/assets/img/gradient-img-06.png)}
	100% {background-image: url(../src/assets/img/gradient-img-01.png)}
} */
.browes-box {
  border: 1px solid #dde2e4;
  padding: 4px 14px;
  border-radius: 4px;
  cursor: pointer;
}
/* .logged-in-wrapper .bg-light{background: #EEF0F2;height: 100%;} */
.logged-in-wrapper .bg-light {
  background: #fff !important;
  height: 100%;
}
.logged-in-wrapper .log-content {
  padding: 80px 215px;
  height: 100vh;
}
.logged-in-wrapper .logo-img {
  display: inline-block;
  width: 264px;
  height: auto;
}
.logged-in-wrapper .logo-wrapper {
  margin-bottom: 94px;
}
.logged-in-wrapper .heading-wrapper h1,
.logged-in-wrapper .heading-wrapper p {
  color: #000;
}
.logged-in-wrapper .form-fields .form-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #000;
}
.logged-in-wrapper .btn-primary {
  width: 100%;
  padding: 8px 16px;
  background: #00a9e7;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #f6f8f9;
}
.logged-in-wrapper .btn-primary:hover {
  background: #008dc0;
}
.logged-in-wrapper .form-fields .form-info-check {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.logged-in-wrapper .form-fields .form-info-check a {
  text-decoration-line: underline;
  font-weight: 500;
  color: #000;
}
.logged-in-wrapper .icon-eye {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #b0babf;
}
.logged-in-wrapper .icon-right-arrow {
  width: 16px;
}
/* Register page css */
.iti.iti--allow-dropdown,
.ngx-intl-tel-input {
  width: 100% !important;
}
ngx-intl-tel-input {
  width: 100%;
}
.dropdown-menu.country-dropdown.show {
  width: 362px;
}
.bg-steps-light {
  background: #edfaff;
  padding: 82px 106px;
  min-height: 100vh;
}
.bg-steps-light .steps-wrapper ul {
  display: inline-block;
  vertical-align: top;
}
.bg-steps-light .steps-wrapper ul li .step-content {
  padding-left: 46px;
  width: 70%;
}
.bg-steps-light .steps-wrapper ul li .step-content h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #252c32;
  margin-bottom: 2px;
}
.bg-steps-light .steps-wrapper ul li .step-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5b6871;
}
.bg-steps-light .steps-wrapper ul li.step {
  position: relative;
  min-height: 115px;
  counter-increment: list;
  display: flex;
  flex-direction: column;
}
.bg-steps-light .steps-wrapper ul li.step:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 26px;
  height: calc(100% - 28px);
  width: 1px;
  border-left: 2px solid #b0babf;
}
.bg-steps-light .steps-wrapper ul li.step:last-child:before {
  display: none;
}
.bg-steps-light .steps-wrapper ul li.step:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background-color: #edfaff;
  border: 2px solid #b0babf;
}
.bg-steps-light .steps-wrapper ul li.step.is-active:after {
  background-color: #fff;
  border: 8px solid #00a9e7;
}
.bg-steps-light .steps-wrapper ul li.step.is-complete:before {
  border-left: 2px solid #00a9e7;
}
.bg-steps-light .steps-wrapper ul li.step.is-complete:after {
  background-color: #edfaff;
  border: 2px solid #00a9e7;
}
.bg-steps-light .steps-wrapper ul li.step.is-complete:after {
  content: url("../src/assets/img/tick-icon.svg");
  color: #00a9e7;
  background: #edfaff;
  border: 2px solid #00a9e7;
}
/* OTP page css */
.logged-in-wrapper .form-fields .otp-num-input input {
  background: #fff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  max-width: 60px;
  width: 100%;
  padding: 29px 16px;
  margin-right: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: black;
}
.logged-in-wrapper .form-fields .otp-num-input input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.logged-in-wrapper .eye-icon {
  position: absolute;
  top: 38px;
  right: 10px;
  color: #b0babf;
}
/* .ng-dropdown-panel.ng-select-bottom{
	top: 380px !important;
} */
/* get verfited css */
.logged-in-wrapper .verification-list ul li span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000;
  padding-left: 26px;
}
.logged-in-wrapper .verification-alert {
  background: #fff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  max-width: 360px;
  width: 100%;
  padding: 24px 27px;
}
.logged-in-wrapper .verification-alert h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #252c32;
}
.logged-in-wrapper .verification-alert img {
  margin-bottom: 20px;
}
.logged-in-wrapper .verification-alert p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5b6871;
}
.logged-in-wrapper .verification-alert .content-warning .btn-primary {
  min-width: 97px;
  padding: 4px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #f6f8f9;
}
/* profile settings */
.dashboard-header {
  background: #fff;
  border-bottom: 1px solid #e5e9eb;
  height: 48px;
  transition: all 0.5s;
  z-index: 997;
  padding: 0 26px;
}
.logged-in-wrapper .verification-alert-box {
  background: #fff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  width: 100%;
}
.dashboard-header .header-nav .nav-icon {
  font-size: 20px;
  color: #9aa6ac;
}
.dashboard-header .header-nav .nav-profile img {
  max-height: 36px;
}
.dashboard-header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 0;
  cursor: pointer;
  color: #000;
}
.dashboard-header .header-nav .badge-number {
  position: absolute;
  padding: 3px 6px;
  width: 20px;
  height: 20px;
  top: -7px;
  border: 2px solid #ffffff;
  border-radius: 50px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffefeb;
  left: 8px;
  min-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-header .header-nav > ul {
  margin: 0;
  padding: 0;
}
/* sidebar css */
.blue-icon {
  display: none;
}
.sidebar .sidebar-nav .nav-item a:hover .blue-icon,
.sidebar .sidebar-nav .nav-item a:active .blue-icon {
  display: block;
}
.sidebar .sidebar-nav .nav-item a:hover .default-icon,
.sidebar .sidebar-nav .nav-item a:active .default-icon {
  display: none;
}


.sidebarAr .sidebar-nav .nav-item a:hover .blue-icon,
.sidebarAr .sidebar-nav .nav-item a:active .blue-icon {
  display: block;
}
.sidebarAr .sidebar-nav .nav-item a:hover .default-icon,
.sidebarAr .sidebar-nav .nav-item a:active .default-icon {
  display: none;
}

#main {
  margin-top: 70px;
  padding: 44px 36px;
  transition: all 0.3s;
  position: relative;
}

#mainAr {
  margin-top: 70px;
  padding: 44px 36px;
  transition: all 0.3s;
  position: relative;
}

#main,
#footer {
  margin-left: 240px;
  

}

#mainAr,
#footerAr {
  margin-right: 240px;
  

}
.toggle-sidebar #main,
.toggle-sidebar #footer {
  margin-left: 0;
  margin-right: 0;
}

.toggle-sidebar #mainAr,
.toggle-sidebar #footerAr {
  margin-left: 0;
  margin-right: 0;
}
.sidebar {
  position: fixed;
  top: 48px;
  left: 0;
  bottom: 0;
  width: 240px;
  z-index: 996;
  transition: all 0.3s;
  padding: 34px 24px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  background: #f6f8f9;
  border-right: 1px solid #e5e9eb;
  overflow-y: hidden;
}
.sidebarAr {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  width: 240px;
  z-index: 996;
  transition: all 0.3s;
  padding: 34px 24px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  background: #f6f8f9;
  border-right: 1px solid #e5e9eb;
  overflow-y: hidden;
}
.toggle-sidebar .sidebar {
  left: -240px;
}
.sidebar .sidebar-logo {
  margin-bottom: 37px;
}
.sidebar .sidebar-logo a {
  width: 156px;
}
.sidebar .sidebar-nav h1 {
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #84919a;
  margin-bottom: 20px;
  padding: 0 10px;
}
.sidebar .sidebar-nav .nav-item {
  border-bottom: 1px solid #e5e9eb;
  margin-bottom: 20px;
}
.sidebar .sidebar-nav .nav-item:nth-child(4),
.sidebar .sidebar-nav .nav-item:last-child {
  border: none;
}
.sidebar .sidebar-nav .nav-link {
  padding: 7px 10px 8px;
  display: flex;
  align-items: center;
  min-width: 208px;
}
.sidebar .sidebar-nav .nav-link i {
  color: #84919a;
  font-size: 16px;
}
.sidebar .sidebar-nav .nav-link span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  padding-left: 12px;
}
.sidebar .sidebar-nav .profile-wrapper {
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
}
.sidebar .sidebar-nav .nav-item a:hover,
.sidebar .sidebar-nav .nav-item a:active {
  background: #aae2f7;
  border-radius: 6px;
}
.sidebar .sidebar-nav .nav-item a:hover span,
.sidebar .sidebar-nav .nav-item a:hover i,
.sidebar .sidebar-nav .nav-item a:active span,
.sidebar .sidebar-nav .nav-item a:active i {
  color: #008dc0;
  font-weight: 600;
}

.sidebar .sidebar-nav .nav-item a:hover svg,
.sidebar .sidebar-nav .nav-item a:active svg {
  fill: #008dc0;
  stroke: #008dc0;
  font-weight: 300;
}

/* Arabic sidebar */
.toggle-sidebar .sidebarAr {
  right: -240px;
}
.sidebarAr .sidebar-logo {
  margin-bottom: 37px;
}
.sidebarAr .sidebar-logo a {
  width: 156px;
}
.sidebarAr .sidebar-nav h1 {
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #84919a;
  margin-bottom: 20px;
  padding: 0 10px;
}
.sidebarAr .sidebar-nav .nav-item {
  border-bottom: 1px solid #e5e9eb;
  margin-bottom: 20px;
}
.sidebarAr .sidebar-nav .nav-item:nth-child(4),
.sidebarAr .sidebar-nav .nav-item:last-child {
  border: none;
}
.sidebarAr .sidebar-nav .nav-link {
  padding: 7px 10px 8px;
  display: flex;
  align-items: center;
  min-width: 208px;
}
.sidebarAr .sidebar-nav .nav-link i {
  color: #84919a;
  font-size: 16px;
}
.sidebarAr .sidebar-nav .nav-link span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  padding-left: 12px;
}
.sidebarAr .sidebar-nav .profile-wrapper {
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
}
.sidebarAr .sidebar-nav .nav-item a:hover,
.sidebarAr .sidebar-nav .nav-item a:active {
  background: #aae2f7;
  border-radius: 6px;
}
.sidebarAr .sidebar-nav .nav-item a:hover span,
.sidebarAr .sidebar-nav .nav-item a:hover i,
.sidebarAr .sidebar-nav .nav-item a:active span,
.sidebarAr .sidebar-nav .nav-item a:active i {
  color: #008dc0;
  font-weight: 600;
}

.sidebarAr .sidebar-nav .nav-item a:hover svg,
.sidebarAr .sidebar-nav .nav-item a:active svg {
  fill: #008dc0;
  stroke: #008dc0;
  font-weight: 300;
}

/* profile page css */
.dashboard-page-title {
  margin: 0 0 35px;
}
.dashboard-page-title h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6e7c87;
  margin-bottom: 15px;
}
.dashboard-page-title h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 5px;
}
.dashboard-page-title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.profile-section .btn.btn-light-blue {
  min-width: 130px;
  height: 40px;
}
.profile-section .form-fields .file-upload-info .file-info p {
  color: #84919a;
  font-size: 12px;
}
.profile-section .form-fields .file-upload-info .cross-icon i {
  color: #f2271c;
  cursor: pointer;
}
.profile-section .form-fields .upload-files-container .drag-file-area {
  border: 1px dashed #d5dadd;
  border-radius: 6px;
  padding: 32px 50px;
  text-align: center;
}
.profile-section .form-fields .upload-files-container .drag-file-area i {
  color: #b0babf;
  margin-bottom: 8px;
}
.profile-section .form-fields .upload-files-container .drag-file-area h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #252c32;
  margin-bottom: 8px;
}
.profile-section
  .form-fields
  .upload-files-container
  .drag-file-area
  .browse-files-text {
  padding: 8px 16px;
  width: 79px;
  height: 40px;
  background: #fff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #252c32;
  text-transform: capitalize;
  cursor: pointer;
}
.profile-section .form-fields .upload-files-container .default-file-input {
  opacity: 0;
  display: none;
}
.profile-section .form-fields .upload-files-container .upload-file-detail {
  background: #f6f8f9;
  border-radius: 6px;
  margin-top: 8px;
  padding: 8px 16px;
}
.profile-section .form-fields .upload-files-container .upload-file-detail span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9aa6ac;
}
.profile-section .form-fields .upload-files-container .upload-file-detail i {
  color: #9aa6ac;
}
footer {
  display: none;
}
/* breadcrumb css */
.solidarity-breadcrumb li a {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6e7c87;
  text-decoration: none;
}
.solidarity-breadcrumb li.active {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #252c32;
}
.solidarity-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

/* Insurance policies css */
.policies-box .box-wrapper {
  width: 70%;
}
.policies-box .box-holder a,
.policies-box .box-holder .emergency-drop-btn {
  width: 180px;
  height: 180px;
  background: #fff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  text-align: center;
  margin: 0 10px 10px 0;
}
.policies-box .box-holder p,
.policies-box .box-holder .emergency-drop-btn p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin: 0;
}
.policies-box .box-holder .img-wrap {
  margin-bottom: 20px;
}
.policies-box .box-holder a:hover,
.policies-box .box-holder .emergency-drop-btn:hover {
  border: 1px solid #008dc0;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 12px 24px rgba(91, 104, 113, 0.24);
}
.policies-box .box-holder a:hover p,
.policies-box .box-holder .emergency-drop-btn:hover p {
  font-weight: 700;
  color: #00719a;
}
.policies-box .box-holder .emergency-drop-btn.dropdown-toggle::after {
  display: none;
}
/* motor and car quote sidebar */
.sidebar-wrapper.bg-steps-light {
  background: transparent;
  padding: 0;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li .step-content h2 {
  font-weight: 400;
  line-height: 20px;
  letter-spacing: normal;
  margin: 0;
  padding: 4px 0 0;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li .step-content {
  padding-left: 38px;
  width: 100%;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step {
  min-height: 60px;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step:before {
  border-left: 2px solid #dde2e4;
  top: 28px;
  height: 51%;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step.is-complete:before {
  border-left: 2px solid #00a9e7;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step:after {
  background-color: transparent;
  border: 2px solid #dde2e4;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step.is-complete:after {
  border: 2px solid #00a9e7;
}
.sidebar-wrapper.bg-steps-light .steps-wrapper ul li.step.is-active:after {
  background-color: #fff;
  border: 8px solid #00a9e7;
}
/* Boxed Input Radio */
.boxed-input-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.boxed-input-radio.myclass .checkmark::after {
  display: none;
}
.boxed-input-radio.myclass .checkmark {
  border-color: #f00;
}
.boxed-input-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.boxed-input-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 180px;
  width: 180px;
  padding: 15px;
  border: 1px solid #dde2e4;
  background-color: transparent;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
}
.boxed-input-radio input:checked ~ .checkmark {
  border-color: #00a9e7;
  background: #e3f8ff;
  color: #00a9e7;
}
.boxed-input-radio .checkmark:after {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #b0babf;
  border-radius: 50%;
  background: #f6f8f9;
  position: absolute;
  top: 10px;
  right: 10px;
}
.boxed-input-radio input:checked ~ .checkmark:after {
  border: 5px solid #00a9e7;
}
.boxed-input-radio .option-name,
.boxed-input-radio .claim-name,
.boxed-input-radio .claim-number {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px;
}

/* radio buttons css */
.video-insurance-options .video-insurance-option {
  position: relative;
  width: 180px;
  margin: 0 12px 12px 0;
}
.video-insurance-options {
  margin: 0 0 160px;
}
.video-insurance-options .video-insurance-option {
  min-height: 180px;
}
.video-insurance-options .option-icon {
  width: 35px;
  margin: 0 0 15px;
}
.video-insurance-options .option-icon.active-icon,
.video-insurance-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-icon.option-icon.default-icon {
  display: none;
}
.video-insurance-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-icon.option-icon.active-icon {
  display: block;
}
.video-insurance-options .option-name {
  font-weight: 600;
  color: #252c32;
}
.video-insurance-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-name {
  color: #00719a;
}

/* date-picker */

::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  background: url("../src/assets/img/datepicker2.svg");
  font-size: 16px;
  background-repeat: no-repeat;
}
::-webkit-datetime-edit-month-field {
  text-transform: uppercase;
  color: #9aa6ac;
}
::-webkit-datetime-edit-day-field {
  text-transform: uppercase;
  color: #9aa6ac;
}
::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
  color: #9aa6ac;
}
::-webkit-datetime-edit-text {
  color: #9aa6ac;
}

/* dropdown chevron-down style */
.ng-select .ng-arrow-wrapper .ng-arrow {
  border: none;
}
.ng-select .ng-arrow-wrapper {
  top: 9px;
}
.ng-select .ng-arrow-wrapper {
  background: url("../src/assets/img/chevron-down.svg");
  background-repeat: no-repeat;
}
/* .ng-dropdown-panel.ng-select-bottom{top:434px !important;position: absolute;} */

/* Custome Radio Button */
.right-label-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}
.right-label-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.right-label-radio .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f6f8f9;
  border: 1px solid #b0babf;
  border-radius: 50%;
}
.right-label-radio input:checked ~ .checkmark {
  border-color: #00a9e7;
  background-color: #00a9e7;
}
.right-label-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.right-label-radio input:checked ~ .checkmark:after {
  display: block;
}
.right-label-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Right Label Checkbox */
.right-label-checkbox {
  display: block;
  position: relative;
  margin: 0 0 12px 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.right-label-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.right-label-checkbox .checkmark {
  position: absolute;
  top: 3px;
  left: -35px;
  height: 20px;
  width: 20px;
  border: 1px solid #b0babf;
  border-radius: 4px;
  cursor: pointer;
}
.right-label-checkbox input:checked ~ .checkmark {
  background-color: #00a9e7;
  border-color: #00a9e7;
}
.right-label-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.right-label-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.right-label-checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkbox input css */
/* .form-condition .form-check-input:checked {background-color: gray;border-color: gray; */
.form-condition .form-check-input:checked {
  background-color: #00a9e7;
  border-color: #00a9e7;
  /* border-radius: 4px; */
}
.form-condition .form-check .form-check-input {
  background: #f6f8f9;
  border: 1px solid #b0babf;
}
.form-condition .form-check-input:focus {
  box-shadow: none;
}
/* motor-car & renewal component css */

.custom-page-form-fields
  .form-fields
  .custom-page-fields-wrapper.motor-select-ons-wrap {
  flex-direction: column;
}
.custom-page-form-fields
  .form-fields
  .custom-page-fields-wrapper.motor-select-ons-wrap
  .form-group {
  margin-bottom: 28px;
}

/* price popup css */
.price-hub-popup {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  text-align: center;
  /* padding: 20px; */
  max-width: 270px;
  position: absolute;
  right: 45px;
  /* top: 115px; */
  top: 20px;
  width: 100%;
  z-index: 1;
}
.price-hub-popup h4 {
  font-weight: 600;
  margin-bottom: 17px;
}
.price-hub-popup h1 {
  font-weight: 700;
  color: #5b6871;
  margin-bottom: 18px;
}
.price-hub-popup .price-info span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #252c32;
}
.price-hub-popup hr {
  border-color: #e5e9eb;
  opacity: 1;
  margin: 17px 0;
}
.price-hub-popup .payment-option {
  background: #ffffff;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7px;
  height: 40px;
}
.price-hub-popup .payment-option p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  margin: 0;
}
.price-hub-popup .payment-option img {
  margin-left: 5px;
}
.price-hub-popup .promo-code {
  margin-bottom: 20px;
  background: #fff1e0;
  border-radius: 5px;
  padding: 20px;
}
.price-hub-popup .promo-code label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  text-align: left;
  text-transform: capitalize;
  display: block;
  margin-bottom: 2px;
}
.price-hub-popup .promo-code input {
  height: 32px;
  background: #ffffff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.price-hub-popup .promo-code .form-control:focus {
  border-color: #00a9e7;
  box-shadow: 0 0 6px rgb(0 169 231);
}
.price-hub-popup .promo-code .input-btn-wrap {
  position: relative;
}
.price-hub-popup .promo-code .btn-apply {
  background: #00a9e7;
  border: 3px solid #80d4f3;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #f6f8f9;
  min-width: 69px;
  height: 38px;
  text-transform: capitalize;
  position: absolute;
  right: 0;
  top: -3px;
}

/*Arabic*/

.price-hub-popupAr {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  text-align: center;
  /* padding: 20px; */
  max-width: 270px;
  position: absolute;
  left: 45px;
  /* top: 115px; */
  top: 20px;
  width: 100%;
  z-index: 1;
}
.price-hub-popupAr h4 {
  font-weight: 600;
  margin-bottom: 17px;
}
.price-hub-popupAr h1 {
  font-weight: 700;
  color: #5b6871;
  margin-bottom: 18px;
}
.price-hub-popupAr .price-info span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #252c32;
}
.price-hub-popupAr hr {
  border-color: #e5e9eb;
  opacity: 1;
  margin: 17px 0;
}
.price-hub-popupAr .payment-option {
  background: #ffffff;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7px;
  height: 40px;
}
.price-hub-popupAr .payment-option p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  margin: 0;
}
.price-hub-popupAr .payment-option img {
  margin-left: 5px;
}
.price-hub-popupAr .promo-code {
  margin-bottom: 20px;
  background: #fff1e0;
  border-radius: 5px;
  padding: 20px;
}
.price-hub-popupAr .promo-code label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  text-align: left;
  text-transform: capitalize;
  display: block;
  margin-bottom: 2px;
}
.price-hub-popupAr .promo-code input {
  height: 32px;
  background: #ffffff;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.price-hub-popupAr .promo-code .form-control:focus {
  border-color: #00a9e7;
  box-shadow: 0 0 6px rgb(0 169 231);
}
.price-hub-popupAr .promo-code .input-btn-wrap {
  position: relative;
}
.price-hub-popupAr .promo-code .btn-apply {
  background: #00a9e7;
  border: 3px solid #80d4f3;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #f6f8f9;
  min-width: 69px;
  height: 38px;
  text-transform: capitalize;
  position: absolute;
  right: 0;
  top: -3px;
}
/* End */

.motor-plan-options p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #84919a;
}
.motor-plan-options .option-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #252c32;
  margin-bottom: 10px;
}
.motor-plan-options .video-insurance-option {
  min-height: 250px;
  width: 250px;
}
.motor-plan-options .boxed-input-radio .checkmark {
  height: 250px;
  width: 100%;
  padding: 60px 20px 0;
}
.motor-plan-options .option-icon {
  width: 55px;
}
.video-insurance-options.motor-plan-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-name {
  color: #252c32;
}

/* accordion css */
.motor-plan-accordion h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #252c32;
  border-bottom: 1px solid #e5e9eb;
  padding-bottom: 7px;
  margin-bottom: 0;
}
.motor-plan-accordion h4 a {
  position: relative;
  padding-left: 33px;
  text-transform: capitalize;
}
.motor-plan-accordion.renewal-plan-accordion h4 a {
  text-transform: initial;
}
.motor-plan-accordion.renewal-plan-accordion h4 {
  font-weight: 400;
}
.motor-plan-accordion .panel.panel-default h4 a:before {
  content: url("./assets/img/side-arrow.svg");
  position: absolute;
  right: 24px;
  left: 0;
}
/* .motor-plan-accordion .panel.panel-default h4 a:after{content: '';position: absolute;left: 6px;top: 50%;height: 2px;width: 10px;background: #9AA6AC;-webkit-backface-visibility: hidden;backface-visibility: hidden;-moz-transition-property: -moz-transform;-o-transition-property: -o-transform;-webkit-transition-property: -webkit-transform;transition-property: transform;-moz-transition-duration: 0.2s;-o-transition-duration: 0.2s;-webkit-transition-duration: 0.2s;transition-duration: 0.2s;-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-webkit-transform: rotate(-45deg);transform: rotate(-45deg);} */
.motor-plan-accordion .panel.panel-default.dropup h4 a:before {
  content: url("./assets/img/down-arrow.svg");
  position: absolute;
  right: 30px;
  backface-visibility: hidden;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
/* .motor-plan-accordion .panel.panel-default.dropup h4 a:after{content: '';-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);position: absolute;right: 24px;top: 50%;height: 2px;width: 10px;background: #9AA6AC;-webkit-backface-visibility: hidden;backface-visibility: hidden;-moz-transition-property: -moz-transform;-o-transition-property: -o-transform;-webkit-transition-property: -webkit-transform;transition-property: transform;-moz-transition-duration: 0.2s;-o-transition-duration: 0.2s;-webkit-transition-duration: 0.2s;transition-duration: 0.2s;} */
.collapse.in {
  display: block;
}
.motor-plan-accordion .collapse.in {
  margin-bottom: 50px;
  width: 120%;

}
.motor-plan-accordion .panel-collapse {
  background: #f6f8f9;
  padding: 20px;
}
.motor-plan-accordion .panel-collapse .content-wrapper p {
  font-size: 14px;
  color: #000;
}
.motor-plan-accordion .panel-collapse .content-wrapper table,
.medical-breakdown table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
}
.motor-plan-accordion .panel-collapse .content-wrapper table tr th,
.medical-breakdown table tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #252c32;
  padding: 15px 16px;
  border-bottom: 1px solid #e5e9eb;
  white-space: nowrap;
}
.motor-plan-accordion .panel-collapse .content-wrapper table tr td,
.medical-breakdown table tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
  padding: 18px 16px;
  border-bottom: 1px solid #e5e9eb;
  white-space: nowrap;
}
.motor-plan-accordion
  .panel-collapse
  .content-wrapper
  table
  tr
  td
  span.text-grey {
  color: #84919a;
}
.motor-plan-accordion .panel {
  margin-bottom: 20px;
}
.video-insurance-options.motor-plan-options {
  margin: 0 0 130px;
}
.motor-plan-accordion table .circle-grey {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50px;
  background: #b0babf;
  margin-right: 12px;
  vertical-align: middle;
}
.motor-plan-accordion table .circle-green {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50px;
  background: #b2d97e;
  margin-right: 12px;
  vertical-align: middle;
}
.motor-plan-accordion table .circle-yellow {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50px;
  background: #f8c51b;
  margin-right: 12px;
  vertical-align: middle;
}
.motor-plan-accordion table .circle-blue {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50px;
  background: #55c6ef;
  margin-right: 12px;
  vertical-align: middle;
}
/*Table Styling */
.table-responsive-parent {
  border: 1px solid #e5e9eb;
  border-radius: 7px;
  overflow: hidden;
}
.table-responsive table {
  margin: 0;
}
.table-responsive table tbody tr:last-child td {
  border-bottom: none;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: none !important;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #00a9e7;
  border: none;
}
.table-responsive::-webkit-scrollbar-track {
  background: #9e9e9e57 !important;
  border: none !important;
}
/* modal styling  */
.modal-backdrop.show {
  opacity: 0.6;
}
.modal-dialog {
  max-width: 560px;
}
.solidarity-modal {
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 40px 64px rgba(91, 104, 113, 0.24);
  border-radius: 6px;
}
.solidarity-modal h2.modal-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #252c32;
}
.solidarity-modal p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #5b6871;
  margin-bottom: 8px;
}
.solidarity-modal .modal-footer {
  border: none;
  padding: 0 24px 24px;
}

.solidarity-modaldoc {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 40px 64px rgba(91, 104, 113, 0.24);
  border-radius: 6px;
}
.solidarity-modaldoc h2.modal-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #252c32;
}
.solidarity-modaldoc p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #5b6871;
  margin-bottom: 8px;
}
.solidarity-modaldoc .modal-footer {
  border: none;
  padding: 0 24px 24px;
}
/* Tooltip style */
.custom-tooltip-des .info-tooltip-icon {
  color: #84919a;
  cursor: pointer;
  text-align: left;
}

/* Tooltip style */
.ngb-tooltip-window {
  text-align: left;
}

/* Tooltip style */
.tooltip {
  text-align: left;
}

.custom-tooltip-des .info-tooltip-icon i {
  font-size: 14px;
  text-align: left;
}
.custom-tooltip-des .tooltip {
  --bs-tooltip-bg: #303940;
  border-radius: 4px;
}
.custom-tooltip-des .tooltip-inner {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #f6f8f9;
}

.tooltip-inner {
  text-align: left;
}
.policy-form-holder {
  max-width: 1100px;
}
.certify-text-holder {
  max-width: 575px;
  margin: 0 0 55px;
}
/* Claim Setup Styles */
/* .file-claim-block{max-width:820px;} */
.file-claim-block {
  max-width: 100%;
}
/* .custom-page-form-fields .file-claim-block .form-fields form{width: 100%;} */
.claim-options .claim-option {
  width: 370px;
  min-height: 175px;
  margin: 0 12px 12px 0;
}
.claim-options .option-icon {
  width: 55px;
  margin: 0 0 15px;
}
.claim-options .option-icon img,
.other-claim-options .claim-box .option-icon {
  width: 100%;
  height: auto;
}
.claim-options .boxed-input-radio .checkmark {
  width: 100%;
}
.claim-options .option-icon.active-icon,
.claim-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-icon.option-icon.default-icon {
  display: none;
}
.claim-options
  .boxed-input-radio
  input:checked
  ~ .checkmark
  .option-icon.option-icon.active-icon {
  display: block;
}
.claim-options .boxed-input-radio input:checked ~ .checkmark .claim-name {
  color: #00719a;
}
.file-claim-block .sub-heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.other-claim-options .claim-box {
  margin: 0 12px 12px 0;
}
.other-claim-options .claim-box a {
  background: #fff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  width: 180px;
  min-height: 180px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.other-claim-options .claim-box .option-icon {
  width: 60px;
  height: 60px;
  margin: 0 0 15px;
}
.other-claim-options .claim-box .option-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.other-claim-options .claim-box p {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.other-claim-options .claim-box .option-icon.default-icon {
  display: block;
}
.other-claim-options .claim-box .option-icon.active-icon {
  display: none;
}
.other-claim-options .claim-box a:hover {
  border-color: #00719a;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 12px 24px rgba(91, 104, 113, 0.24);
}
.other-claim-options .claim-box a:hover p {
  color: #00719a;
  font-weight: 600;
}
.other-claim-options .claim-box a:hover .option-icon.default-icon {
  display: none;
}
.other-claim-options .claim-box a:hover .option-icon.active-icon {
  display: block;
}
.file-claim-block .form-fields .form-group {
  margin-bottom: 40px;
}
.file-claim-block .form-fields .upload-files-container .drag-file-area {
  border: 1px dashed #d5dadd;
  /* background: #F6F8F9; */
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.file-claim-block
  .form-fields
  .upload-files-container
  .drag-file-area.after-upload-file-area {
  background: #f6f8f9;
  border: 2px solid #5b6871;
  position: relative;
}
.file-claim-block
  .form-fields
  .upload-files-container
  .drag-file-area.after-upload-file-area
  .upload-file-icon {
  position: absolute;
  right: 50px;
  bottom: 50px;
}
.file-claim-block .agree-btn {
  min-width: 220px;
}
.file-claim-block .file-agree-content {
  width: 58%;
  margin-bottom: 90px;
}
.file-claim-block .file-agree-content p a {
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}
.file-claim-block .file-agree-content p a:hover {
  color: #00a9e7;
}
/* claim dropdown css */
.claim-dropdown {
  cursor: auto;
  width: 560px;
  padding: 20px 30px 20px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 40px 64px rgba(91, 104, 113, 0.24);
}

.claim-dropdownAr {
  cursor: auto;
  width: 560px;
  padding: 20px 30px 20px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 40px 64px rgba(91, 104, 113, 0.24);
}
.claim-dropdown .h2 {
  font-size: 24px;
  line-height: 32px;
  color: #252c32;
  font-family: "Roboto", sans-serif;
}

.claim-dropdownAr .h2 {
  font-size: 24px;
  line-height: 32px;
  color: #252c32;
  font-family: "Roboto", sans-serif;
}
.claim-dropdown .search-form {
  margin: 0 0 30px;
}
.claim-dropdownAr .search-form {
  margin: 0 0 30px;
}
.claim-dropdown .search-form label {
  font-size: 14px;
  line-height: 18px;
  color: #252c32;
  margin: 0 0 5px;
}

.claim-dropdownAr .search-form label {
  font-size: 14px;
  line-height: 18px;
  color: #252c32;
  margin: 0 0 5px;
}
.claim-dropdown .search-form .input-holder {
  width: calc(100% - 40px);
  margin: 0 0 5px;
}
.claim-dropdownAr .search-form .input-holder {
  width: calc(100% - 40px);
  margin: 0 0 5px;
}
.claim-dropdown .search-form input[type="text"] {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dde2e4;
  border-radius: 6px 0px 0px 6px;
  transition: all ease-in-out 0.3;
  padding: 0 10px 0 10px;
}

.claim-dropdownAr .search-form input[type="text"] {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dde2e4;
  border-radius: 6px 0px 0px 6px;
  transition: all ease-in-out 0.3;
  padding: 0 10px 0 10px;
}
.claim-dropdown .search-form input[type="text"]:focus {
  border-color: #5b6871;
  outline: none;
  box-shadow: none;
}

.claim-dropdownAr .search-form input[type="text"]:focus {
  border-color: #5b6871;
  outline: none;
  box-shadow: none;
}
.claim-dropdown .search-form button[type="submit"] {
  width: 40px;
  height: 40px;
  border: solid #dde2e4;
  border-width: 1px 1px 1px 0;
  border-radius: 0px 6px 6px 0px;
  background: #f6f8f9;
}
.claim-dropdown .search-form button[type="submit"]:hover {
  background: #00a9e7;
  border-color: #00a9e7;
  color: #fff;
}
.claim-dropdown .terms-text {
  font-size: 12px;
  line-height: 16px;
  color: #9aa6ac;
}
.claim-dropdown .notification-items > li {
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  margin: 0 0 10px;
  padding: 15px;
}

.claim-dropdownAr .notification-items > li {
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  margin: 0 0 10px;
  padding: 15px;
}
.claim-dropdown .notification-items .image-holder {
  width: 100px;
  overflow: hidden;
  border-radius: 6px;
  margin: 0 15px 0 0;
}
.claim-dropdownAr .notification-items .image-holder {
  width: 100px;
  overflow: hidden;
  border-radius: 6px;
  margin: 0 15px 0 0;
}
.claim-dropdown .notification-items {
  font-size: 14px;
  line-height: 24px;
  color: #000;
  max-height: 560px;
  overflow-y: auto;
}

.claim-dropdownAr .notification-items {
  font-size: 14px;
  line-height: 24px;
  color: #000;
  max-height: 560px;
  overflow-y: auto;
}
.claim-dropdown .notification-items h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin: 0 0 15px;
}

.claim-dropdownAr .notification-items h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin: 0 0 15px;
}
.claim-dropdown .workshop-meta {
  font-size: 14px;
  line-height: 18px;
  color: #252c32;
}
.claim-dropdown .workshop-meta li {
  margin: 0 30px 0 0;
}
.claim-dropdown .workshop-meta i.bi {
  font-size: 18px;
  margin: 0 10px 0 0;
}
.claim-dropdown .workshop-meta a {
  color: #9aa6ac;
}
.claim-dropdown .workshop-meta a:hover {
  color: #00a9e7;
}
/* Emergency servcie dropdown */
.claim-dropdown.emergency-service-dropdown .notification-items h2 {
  margin: 0 0 10px;
}

.claim-dropdownAr.emergency-service-dropdown .notification-items h2 {
  margin: 0 0 10px;
}
.claim-dropdown.emergency-service-dropdown .workshop-meta a {
  color: #252c32;
  border: none;
}
.claim-dropdown.emergency-service-dropdown .workshop-meta a:hover {
  color: #00a9e7;
  box-shadow: none;
}
.claim-dropdown.emergency-service-dropdown .roadside-img-holder {
  margin-bottom: 35px;
}
.claim-dropdown.emergency-service-dropdown .terms-text {
  font-size: 16px;
  line-height: 24px;
  color: #9aa6ac;
  margin-bottom: 15px;
  display: block;
}
.claim-dropdown.emergency-service-dropdown
  .notification-items::-webkit-scrollbar {
  width: 4px;
}
.home-insurance-form.home-insurance-info-form label {
  color: #000;
}
.form-fields
  .home-insurance-form.home-insurance-info-form
  .upload-files-container
  .drag-file-area {
  padding: 21px 50px;
}
/* table styling in some pages */
.motor-plan-accordion.view-policy-accordion .tbl-border {
  background: #ffffff;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
}
.motor-plan-accordion.view-policy-accordion .table-wrapper.row {
  margin: 0 -6px 32px;
}
.motor-plan-accordion.view-policy-accordion .table-wrapper.row .col-md-6,
.motor-plan-accordion.view-policy-accordion .table-wrapper.row .col-md-12 {
  padding: 0 6px;
}
.motor-plan-accordion.view-policy-accordion .content-wrapper table {
  border: none;
}
.motor-plan-accordion.domestic-accordion
  .panel-collapse
  .content-wrapper
  table
  tr:first-child
  td {
  width: 700px;
}
.motor-plan-accordion.domestic-accordion .list-wrapper p,
.motor-plan-accordion.domestic-accordion .list-wrapper ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.motor-plan-accordion.domestic-accordion .list-wrapper ul {
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 40px;
}
.motor-plan-accordion.domestic-accordion .list-wrapper p {
  width: 70%;
  margin-bottom: 20px;
}
.motor-plan-accordion.view-policy-accordion.domestic-accordion
  .table-wrapper.row {
  margin: 0 -6px 0;
}
/* Renewal */
.policy-renew-text {
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.renewal-options {
  margin: 0 0 32px;
}
.motor-plan-options.renewal-options .video-insurance-option {
  min-height: 175px;
  width: 370px;
}
.motor-plan-options.renewal-options .boxed-input-radio .checkmark {
  height: 195px;
  width: 370px;
  padding: 20px 10px 0;
}
.renewal-review-pay {
  background: #f6f8f9;
  /* padding: 20px; */
}
.custom-page-form-fields
  .form-fields
  .custom-page-fields-wrapper.motor-select-ons-wrap.renewal-review-pay
  .form-group:last-child {
  margin-bottom: 0;
}
.renewal-plan-accordion {
  max-width: 560px;
  width: 100%;
}
/* travel and medical insurance css */
.travel-border-bottom {
  border-bottom: 1px solid #e5e9eb;
  margin-bottom: 20px;
}
.home-insurance-form.travel-insurance-form .text-red:hover {
  color: #f2271c;
}
.traveling-info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
/* success-modal css */
.success-modal .icon-check {
  margin-right: 20px;
}
.success-modal .text-wrap,
.success-modal .modal-footer {
  margin-left: 36px;
}
.success-modal h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #252c32;
  margin-bottom: 15px;
}
.success-modal p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #5b6871;
}
.success-modal .icon-check i {
  color: #47d16c;
}
.success-modal .file-upload-info {
  background: #ffffff;
  border: 1px solid #e5e9eb;
  border-radius: 5px;
  padding: 20px 20px;
}
.success-modal .box-holder .img-wrap {
  margin-right: 10px;
}
.success-modal .box-holder .file-upload-info .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252c32;
}
.success-modal .box-holder .file-upload-info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #84919a;
}
.success-modal .modal-footer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #5b6871;
}
.success-modal .modal-footer p a {
  text-decoration: underline;
  color: #5b6871;
  font-weight: bold;
}
.success-modal .download-icon {
  cursor: pointer;
}
.success-modal .cross-icon-wrap i {
  color: #00384d;
  font-size: 25px;
  cursor: pointer;
}
.policies-box .cross-icon-wrap {
  text-align: right;
  cursor: pointer;
}
.policies-box .cross-icon-wrap i {
  color: #252c32;
  font-size: 30px;
  font-weight: bold;
}
.claim-dropdown.emergency-service-dropdown {
  padding-top: 20px;
}
.policies-box .dropdown {
  position: static !important;
}
.claim-dropdown.emergency-service-dropdown {
  padding-top: 20px;
  top: -48px !important;
  right: 0 !important;
  left: auto !important;
  -o-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  -moz-transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}

.claim-dropdown.emergency-service-dropdown {
  padding-top: 20px;
  top: -48px !important;
  right: 0 !important;
  left: auto !important;
  -o-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  -moz-transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}
.action-icon {
  cursor: pointer;
}
/* .motor-plan-accordion .panel.panel-default h4 a:before{background: url("./assets/img/side-arrow.svg");font-size: 50px;}
.motor-plan-accordion .panel.panel-default h4 a::after{display: none;} */
/* Bredcrumb style */
.solidarity-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: url("../src/assets/img/breadcrumb.svg");
}
/* Responsive Queries */
@media (max-width: 1750px) {
  .policies-box .box-holder .img-wrap {
    width: 50px;
  }
  .dashboard-page-title h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .motor-plan-accordion .panel-collapse .content-wrapper table tr td,
  .medical-breakdown table tr td {
    padding: 12px 12px;
  }
  .policies-box .box-wrapper {
    width: 90%;
  }
  h1 {
    font-size: 28px;
    line-height: 30px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
  .logged-in-wrapper .logo-wrapper {
    margin-bottom: 60px;
  }
  .logged-in-wrapper .log-content {
    padding: 60px 190px;
  }
  .logged-in-wrapper .logo-img {
    width: 200px;
  }
  .bg-steps-light .steps-wrapper ul li .step-content {
    width: 90%;
  }
  .sidebar .sidebar-logo a {
    width: 150px;
  }
  /* .sidebar .sidebar-nav .nav-link{padding: 0 0 8px;} */
  .sidebar .sidebar-nav .nav-item a.active {
    background: #aae2f7;
    border-radius: 6px;
  }
  .sidebar .sidebar-nav .nav-item {
    margin-bottom: 15px;
  }

  .sidebarAr .sidebar-logo a {
    width: 150px;
  }
  /* .sidebar .sidebar-nav .nav-link{padding: 0 0 8px;} */
  .sidebarAr .sidebar-nav .nav-item a.active {
    background: #aae2f7;
    border-radius: 6px;
  }
  .sidebarAr .sidebar-nav .nav-item {
    margin-bottom: 15px;
  }
  #main {
    margin-top: 48px;
    padding: 25px 20px;
  }
  #mainAr {
    margin-top: 48px;
    padding: 25px 20px;
  }
  .dashboard-page-title h6 {
    margin-bottom: 10px;
  }
  .profile-section .form-fields .form-group {
    margin-bottom: 20px;
  }
  .sidebar .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }
  /* .dashboard-page-title h1{margin-bottom: 0;} */
  .sidebar {
    padding: 15px 16px;
  }

  .sidebarAr .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }
  /* .dashboard-page-title h1{margin-bottom: 0;} */
  .sidebarAr {
    padding: 15px 16px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .motor-plan-options .option-name {
    font-size: 16px;
    line-height: 20px;
  }
  .motor-plan-accordion .panel-collapse .content-wrapper table tr td {
    font-size: 12px;
    line-height: 22px;
  }
  .motor-plan-options .video-insurance-option {
    min-height: 220px;
  }
  .motor-plan-options .boxed-input-radio .checkmark {
    height: 220px;
    padding: 40px 10px 0;
  }
  .custom-page-form-fields .form-fields form {
    width: 100%;
  }
}
@media (max-width: 1699px) {
  .policy-form-holder {
    max-width: 1060px;
  }
}
@media (max-width: 1599px) {
  .motor-plan-options .video-insurance-option {
    width: 192px;
  }
  .motor-plan-options .boxed-input-radio .checkmark {
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .logged-in-wrapper .log-content {
    padding: 60px 120px;
  }
  .claim-dropdown {
    padding: 30px 30px 20px;
  }
  .policy-form-holder {
    max-width: 900px;
  }
  .policies-box .box-wrapper {
    width: 100%;
  }
  .sidebar .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }

  .sidebarAr .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1365px) {
  .motor-plan-options .video-insurance-option {
    width: 50%;
  }
}
@media (max-width: 1199px) {
  .policies-box .box-holder .emergency-drop-btn {
    width: 100%;
  }
  .claim-dropdown .notification-items .image-holder {
    width: 80px;
  }
  .claim-dropdown .notification-items h2 {
    font-size: 14px;
    line-height: 16px;
  }
  .claim-dropdown .notification-items p {
    margin-bottom: 8px;
  }
  .claim-dropdown .notification-items > li {
    padding: 10px 10px;
  }
  .claim-dropdown.emergency-service-dropdown .roadside-img-holder {
    margin-bottom: 10px;
  }
  .claim-dropdown.emergency-service-dropdown .terms-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .claim-dropdown.emergency-service-dropdown h2 {
    margin-bottom: 10px !important;
  }
  .policies-box .box-wrapper {
    width: 100%;
    margin: 0 -5px;
  }
  .policies-box .box-wrapper .box-holder {
    width: 50%;
    padding: 0 5px;
  }
  .policies-box .box-wrapper .box-holder a {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .video-insurance-options.motor-plan-options {
    margin: 0 -12px 30px;
  }
  .motor-plan-options .video-insurance-option {
    width: 50%;
    padding: 0 6px;
    margin: 0 0 10px 0;
  }
  .policy-form-holder {
    max-width: 100%;
  }
  .motor-plan-accordion .panel-collapse .content-wrapper table tr td {
    padding: 15px 16px;
  }
  .custom-page-form-fields .form-fields .form-group {
    margin-right: 0;
    max-width: 100%;
  }
  .custom-page-form-fields .form-fields .form-group {
    margin-bottom: 30px;
  }
  .file-claim-block {
    max-width: 100%;
  }
  /* claim setup style */
  .other-claim-options,
  .claim-options {
    margin: 0 -8px;
  }
  .other-claim-options .claim-box {
    margin: 0 0 12px 0;
    padding: 0 8px;
    width: 25%;
  }
  .other-claim-options .claim-box a {
    width: 100%;
  }
  .claim-options .claim-option {
    width: 50%;
    padding: 0 8px;
    margin: 0 0 12px 0;
  }
  .other-claim-options .claim-box p {
    font-size: 13px;
    line-height: 18px;
  }
  /* Renewal */
  .video-insurance-options.motor-plan-options.renewal-options {
    margin: 0 -12px 32px;
  }
  .motor-plan-options.renewal-options .video-insurance-option {
    width: 50%;
    margin: 0 0 12px 0;
    padding: 0 12px;
  }
  .motor-plan-options.renewal-options .boxed-input-radio .checkmark {
    width: 100%;
  }
  .renewal-plan-accordion {
    max-width: 100%;
  }
  .price-hub-popup {
    position: static;
    top: auto;
    right: auto;
    max-width: 280px;
    margin: 0 auto 30px;
  }

  .price-hub-popupAr {
    position: static;
    top: auto;
    right: auto;
    max-width: 280px;
    margin: 0 auto 30px;
  }
  .bg-steps-light {
    padding: 82px 46px;
  }
}
@media (max-width: 991px) {
  .sidebar .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }

  .sidebarAr .sidebar-nav .nav-link span,
  .form-fields .form-label,
  .profile-section .form-fields .upload-files-container .drag-file-area h3,
  .profile-section
    .form-fields
    .upload-files-container
    .drag-file-area
    .browse-files-text,
  .profile-section
    .form-fields
    .upload-files-container
    .upload-file-detail
    span,
  .dashboard-page-title p {
    font-size: 14px;
    line-height: 20px;
  }
  .video-insurance-options.motor-plan-options {
    margin: 0 -6px 48px;
  }
  .custom-page-form-fields .form-fields .custom-page-fields-wrapper {
    justify-content: space-between;
  }
  .sidebar {
    left: -240px;
  }

  .sidebarAr {
    right: -240px;
  }
  #main,
  #footer {
    margin-left: 0;
  }

  #mainAr,
  #footerAr {
    margin-right: 0;
  }
  .toggle-sidebar .sidebar {
    left: 0;
  }

  .toggle-sidebar .sidebarAr {
    right: 0;
  }
  /* .toggle-sidebar #main, .toggle-sidebar #footer {margin-left: 240px;} */
  .logged-in-wrapper .bg-img {
    display: none;
  }
  /* .logged-in-wrapper .heading-wrapper{text-align: center;} */
  /* .logged-in-wrapper .log-content{padding: 60px 20px;} */
  .motor-plan-options .video-insurance-option {
    width: 50%;
    padding: 0 6px;
    margin: 0 0 10px 0;
  }
  .motor-plan-options .boxed-input-radio .checkmark {
    width: 100%;
  }
  .bg-steps-light {
    padding: 82px 50px;
  }
  .logged-in-wrapper .logo-wrapper {
    text-align: center;
  }
  .field-section {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .certify-text-holder {
    max-width: 100%;
  }
  /* .price-hub-popup {max-width: 60%;right: 0;} */
  .form-fields textarea.form-control {
    height: 120px;
  }
  /* claim setup style */
  .other-claim-options .claim-box {
    width: 50%;
  }
  .file-claim-block .file-agree-content {
    width: 100%;
    margin-bottom: 50px;
  }
  .file-claim-block .form-fields {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .logged-in-wrapper .logo-wrapper {
    text-align: center;
    margin: 0 0 20px;
  }
  .logged-in-wrapper .log-content {
    padding: 0;
  }
  .login-form-content {
    display: block !important;
    padding: 30px 15px;
    overflow-y: auto;
  }
  .login-form-content .form-fields {
    margin: 0 auto;
  }
}
@media (max-width: 575px) {
  .motor-plan-accordion .panel-collapse {
    padding: 20px 15px;
  }
  .certify-text-holder {
    max-width: 100%;
    margin: 0 0 30px;
  }
  .claim-dropdown,
  .dropdown-outer-box,
  .motor-plan-options .video-insurance-option {
    width: 100%;
  }
  .policies-box .box-wrapper .box-holder {
    width: 100%;
  }
  /* .price-hub-popup {max-width: 80%; padding: 20px;} */
  .custom-page-form-fields .form-fields .form-group {
    max-width: 100%;
  }
  .custom-page-form-fields .form-fields .form-group {
    margin-bottom: 20px;
  }
  .motor-plan-options .video-insurance-option,
  .motor-plan-options .boxed-input-radio .checkmark {
    width: 100%;
  }
  .video-insurance-options .video-insurance-option {
    margin: 0 0 12px 0;
  }
  /* claim setup style */
  .other-claim-options .claim-box,
  .claim-options .claim-option {
    width: 100%;
  }
  .file-claim-block
    .form-fields
    .upload-files-container
    .drag-file-area.after-upload-file-area
    .upload-file-icon {
    right: 30px;
  }
  /* Renewal */
  .motor-plan-options.renewal-options .video-insurance-option {
    width: 100%;
  }
  .renewal-plan-accordion .renewal-review-pay {
    padding: 0;
  }
  .error-404 .error-img-holder {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .password-field {
    min-width: auto;
  }
  .success-modal .icon-check {
    margin-right: 10px;
  }
  .success-modal .text-wrap,
  .success-modal .modal-footer {
    margin-left: 0;
  }
  .success-modal .box-holder {
    flex-direction: column !important;
  }
  .success-modal .box-holder .img-wrap {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .other-claim-options .claim-box a {
    min-height: 150px;
  }
  .other-claim-options .claim-box .option-icon {
    width: 50px;
    height: 50px;
  }
  /* .file-claim-block .sub-heading {margin-bottom: 30px !important;} */
  .boxed-input-radio .option-name,
  .boxed-input-radio .claim-name,
  .boxed-input-radio .claim-number {
    font-size: 16px;
    line-height: 22px;
  }
  .claim-dropdown {
    padding: 20px 15px 20px;
  }
  .claim-dropdown .notification-items > li {
    padding: 10px 10px;
    flex-direction: column;
    align-items: center;
  }
  .claim-dropdown .notification-items > li .inf-holder {
    text-align: center;
  }
  .claim-dropdown .notification-items > li .image-holder {
    margin-bottom: 10px;
  }
  .claim-dropdown.emergency-service-dropdown .notification-items h2 {
    margin: 0 0 0px !important;
  }
  .claim-dropdown .workshop-meta li {
    margin: 0 0 0 0;
  }
  .claim-dropdown .notification-items .image-holder {
    width: 70px;
    margin: 0 0 0 0;
  }
  .policy-form-holder .btns-holder {
    flex-direction: column;
  }
  .policy-form-holder .btns-holder .btn-light-blue.me-2 {
    margin-right: 0 !important;
    margin-bottom: 8px !important;
  }
  .policy-form-holder .btn-wrapper .btn-light-blue,
  .file-claim-block .btn-wrapper .btn-light-blue {
    width: 100%;
  }
  h1 {
    font-size: 25px;
    line-height: 28px;
  }
  .price-hub-popup .payment-option {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  .price-hub-popup .payment-option img {
    margin: 0 5px;
  }
  .price-hub-popup .promo-code input {
    min-width: 100%;
  }
  .price-hub-popup .promo-code {
    padding: 20px 10px;
    height: 140px;
  }

  .price-hub-popupAr .payment-option {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  .price-hub-popupAr .payment-option img {
    margin: 0 5px;
  }
  .price-hub-popupAr .promo-code input {
    min-width: 100%;
  }
  .price-hub-popupAr .promo-code {
    padding: 20px 10px;
    height: 140px;
  }
  .logged-in-wrapper .log-content {
    padding: 60px;
  }
  .logged-in-wrapper .logo-wrapper {
    margin-bottom: 40px;
  }
  .motor-plan-options .video-insurance-option,
  .motor-plan-options .boxed-input-radio .checkmark {
    width: 100%;
  }
  .motor-plan-options .video-insurance-option {
    min-height: 190px;
  }
  .motor-plan-options .boxed-input-radio .checkmark {
    height: 190px;
  }
  .motor-plan-options .boxed-input-radio .checkmark {
    padding: 30px 10px 0;
  }
  .price-hub-popup .promo-code .input-btn-wrap {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .price-hub-popup .promo-code input {
    width: 100%;
    border-radius: 6px;
  }
  .price-hub-popup .promo-code .btn-apply {
    width: 100%;
    top: 37px;
  }

  .price-hub-popupAr .promo-code .input-btn-wrap {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .price-hub-popupAr .promo-code input {
    width: 100%;
    border-radius: 6px;
  }
  .price-hub-popupAr .promo-code .btn-apply {
    width: 100%;
    top: 37px;
  }
  /* .price-hub-popup {max-width: 90%;left: 0;right: 0;margin: 0 auto;} */
}
@media (max-width: 380px) {
  .logged-in-wrapper .log-content {
    padding: 20px 20px;
  }
  .dropdown-menu.country-dropdown.show {
    width: 300px;
  }
}
@media (max-width: 320px) {
  .logged-in-wrapper .logo-img {
    width: 160px;
  }
  p,
  .logged-in-wrapper .form-fields .form-control,
  .logged-in-wrapper .form-fields .form-label,
  .logged-in-wrapper .form-fields .form-info-check,
  .logged-in-wrapper .form-fields .form-link,
  .logged-in-wrapper .btn-primary {
    font-size: 12px;
  }
  .logged-in-wrapper .logo-wrapper {
    margin-bottom: 20px;
  }
  .dropdown-menu.country-dropdown.show {
    width: 230px;
  }
  .logged-in-wrapper .form-fields .otp-num-input input {
    padding: 0;
    width: 40px;
  }

  
}
